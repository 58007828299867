import { useEffect, useState } from 'react';
import { FaBarcode } from 'react-icons/fa6';
import { IoClose } from 'react-icons/io5';
import { MdGrid3X3, MdSpeed } from 'react-icons/md';

import { useSocketIO } from '@/context/socket';
import { ReloadIcon } from '@radix-ui/react-icons';

import { Image } from './Image';

interface ProductProps {
	id?: number;
	barcode?: string;
	name: string;
	price?: number;
	unit?: string;
	OBS?: string;
}

const beep = new Audio('/bip-notfound.mp3');
function soundNotFound() {
	beep.play();
	if (navigator && navigator.vibrate) {
		navigator.vibrate([500, 100, 200, 100, 200]);
	}
}

export function Product({
	barcode,
	isLoading,
	loading,
}: {
	barcode: string;
	isLoading: (value: boolean) => void;
	loading: boolean;
}) {
	const [product, setProduct] = useState<ProductProps | null>({
		name: 'PRONTO PARA INICIAR',
		OBS: 'Faça sua primeira consulta agora!',
	});
	const { socket, statusSocket } = useSocketIO();
	const [secondsExec, setSecondsExec] = useState(0);

	function updateProduct(data: any) {
		if (data.length === 0) {
			soundNotFound();
			setProduct({
				name: 'NÃO ENCONTRADO',
				price: 0,
				barcode: barcode,
				id: 0,
			});
			return;
		}

		// const product = data[0];

		// Remover
		const header: string[] = data[0];

		const product = header.reduce(
			(product: any, header: any, index: number) => {
				product[header] = data[1][index];
				return product;
			},
			{},
		);

		setProduct(product);
	}

	useEffect(() => {
		switch (statusSocket) {
			case 'reconnected':
				setProduct(() => ({
					name: 'RECONECTANDO AO SERVIDOR...',
					OBS: 'Estamos estabelecendo conexão novamente, aguarde...',
				}));
				break;
			case 'connecting':
				setProduct(() => ({
					name: 'CONECTANDO AO SERVIDOR...',
					OBS: 'Estamos conectando ao servidor, aguarde...',
				}));
				break;
			case 'disconnected':
				setProduct(() => ({
					name: 'DESCONECTADO',
					OBS: 'Consulta Preço desconectado. Tente novamente!',
				}));
				break;
			case 'connected':
				setProduct(() => ({
					name: 'PRONTO PARA INICIAR',
					OBS: 'Faça sua primeira consulta agora!',
				}));
				break;
			case 'business_not_found':
				setProduct(() => ({
					name: 'EMPRESA NÃO CADASTRADA',
					OBS: 'Opa! O CNPJ informado não está em nosso sistema. Atualize a página e tente novamente! Se o problema persistir entre em contato com o suporte.',
				}));
				break;
			case 'token_invalid':
				setProduct(() => ({
					name: 'TOKEN INVÁLIDO',
					OBS: 'Opa! O token informado é inválido. Atualize a página e tente novamente! Se o problema persistir entre em contato com o suporte.',
				}));
				break;
			case 'agent_not_found':
				setProduct(() => ({
					name: 'O AGENTE NÃO ESTÁ CONECTADO',
					OBS: 'Verifique se o agente está rodando na máquina servidor. Se o problema persistir entre em contato com o suporte.',
				}));
				break;
			default:
				break;
		}
	}, [statusSocket]);

	useEffect(() => {
		if (loading) {
			setProduct(() => ({
				name: 'BUSCANDO...',
				OBS: 'Estamos buscando as informações, um instante...',
			}));
		}
	}, [loading]);

	useEffect(() => {
		if (!!barcode && statusSocket === 'connected') {
			isLoading(true);
			const start = performance.now();

			socket.emit('consult', {
				consult: barcode.length < 7 ? 'productByCode' : 'productByBarCode',
				data: barcode,
			});

			socket.once('resultConsult', (response) => {
				const end = performance.now();

				updateProduct(response.data);
				isLoading(false);
				setSecondsExec(end - start);
			});
		}
	}, [barcode]);

	return (
		<div className="flex h-[85%] w-full flex-col justify-between gap-4 h-md:h-[60%]">
			{product?.barcode && product?.name !== 'NÃO ENCONTRADO' ? (
				<Image
					src={`https://cdn-cosmos.bluesoft.com.br/products/${product?.barcode}`}
					alt=""
					className="mx-auto h-44 min-w-[10rem] max-w-[90%] rounded-2xl"
					typeLoading="bar"
				/>
			) : product?.name !== 'NÃO ENCONTRADO' ? (
				<div className="h-44" />
			) : (
				<div className="h-44">
					<IoClose className="h-36 w-full text-destructive" />
				</div>
			)}
			<div
				data-noresult={product?.name === 'NÃO ENCONTRADO'}
				className="mx-auto flex w-[80%] max-w-[36rem] flex-col items-center justify-center rounded-2xl text-primary shadow-lg data-[noresult=true]:text-destructive"
			>
				<h1 className="w-full rounded-t-2xl bg-current p-3 py-2 text-center text-lg font-semibold leading-6">
					<span className="flex items-center justify-center gap-1 text-white">
						{loading && <ReloadIcon className="h-4 w-4 animate-spin" />}
						{product?.name}
					</span>
				</h1>
				<div className="flex w-full flex-col items-center gap-4 rounded-b-2xl border bg-white p-4 pt-6">
					{product?.OBS && (
						<p className="text-center font-medium text-foreground">
							{product?.OBS}
						</p>
					)}

					<p className="text-5xl font-bold leading-10 text-foreground">
						{product?.price?.toLocaleString('pt-BR', {
							style: 'currency',
							currency: 'BRL',
						})}
						{product?.unit === 'KG' ? (
							<span className="text-base text-zinc-500">/kg</span>
						) : (
							''
						)}
					</p>

					{product?.barcode && (
						<div className="flex flex-wrap justify-center gap-x-4">
							{product?.id ? (
								<p className="flex items-center gap-1">
									<MdGrid3X3 className="" size={20} />
									<span className="text-foreground">{product?.id}</span>
								</p>
							) : (
								''
							)}
							<p className="flex items-center gap-2">
								<FaBarcode className="" />
								<span className="text-foreground">{product?.barcode}</span>
							</p>
							<p className="flex items-center gap-1">
								<MdSpeed size={20} className="" />
								<span className="text-foreground">
									{(secondsExec / 1000).toFixed(2)}s
								</span>
							</p>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
